.tenantItem {
  height: 70px;
  width: 75%;
  background-color: #f2f2f6;
  border: none;
  margin-top: 20px;
  cursor: pointer;

  position: relative;
  border-radius: 10px
}