.page-container {
  &-header {
    box-sizing: border-box;
    position: fixed;
    display: flex;
    align-items: center;
    padding: 16px 80px;
    width: 100vw;
    height: 72px;
    z-index: 999;

    &-user {
      height: 40px;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-family: alibaba-puhuiti;

      line-height: 40px;

      &-use {
        padding: 0 16px;
        margin-right: 24px;
      }

      &-use:hover {
        cursor: pointer;
        background: linear-gradient(180deg, #789EFF 0%, #4C5FFF 66%, #414EFF 100%);
        border-radius: 2px;
      }

      &-about {
        padding: 0 16px;
        margin-right: 24px;
      }

      &-about:hover {
        cursor: pointer;
        background: linear-gradient(180deg, #789EFF 0%, #4C5FFF 66%, #414EFF 100%);
        border-radius: 2px;
      }

      &-active {
        background: linear-gradient(180deg, #789EFF 0%, #4C5FFF 66%, #414EFF 100%);
        border-radius: 2px;
      }
    }
  }

  &-home {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    &-backimg {
      position: absolute;
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      z-index: -3;
    }

    &-video {
      width: 100vw;
      height: 100vh;
      object-fit: cover;
      z-index: -1;
    }

    &-button {
      text-decoration: none;
      position: absolute;
      left: calc(50vw - 77px);
      bottom: 26%;
      width: 154px;
      height: 64px;
      background: linear-gradient(180deg, #789EFF 0%, #4C5FFF 66%, #414EFF 100%);
      border-radius: 3px;
      font-size: 26px;
      font-family: alibaba-puhuiti;

      color: #FFFFFF;
      line-height: 38px;
      text-align: center;
      padding-top: 13px;
    }

    &-button:hover {
      cursor: pointer;
    }
  }

  &-use {
    position: relative;

    &-video {
      position: absolute;
      display: flex;
      left: 0;
      top: 0;
      justify-content: center;
      align-items: center;
      width: 100vw;
      height: 100vh;
    }
  }

  &-about {
    position: relative;

    &-text {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &-copyright {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    &-link {
      color: white;
      text-decoration: none;
    }
  }
}

// .image-1 {
//   width: 100vw;
//   height: 100vh;
// }

.modal :global(.ant-modal-content) {
  padding: 0;
}

.modal-container {
  height: 600px;
  display: flex;
  overflow: hidden;

  &-left {
    overflow: hidden;

    &-logo {
      position: absolute;
      left: 64px;
      top: 64px;
      z-index: 1
    }
  }

  &-right {
    width: 368px;
    margin-left: 64px;
    margin-right: 128px;
    flex-direction: column;
    display: flex;

    &-submit-btn {
      width: 100%;
      background: linear-gradient(180deg, #789EFF 0%, #4C5FFF 66%, #414EFF 100%);
      margin-bottom: 16px;
      margin-top: 16px;
    }

    &-protocol {
      margin-left: 58px;
      margin-top: 24px;
    }

    &-login-title {
      margin-top: 90px;
      font-size: 32px;
      font-weight: 500;
      color: #262626;
    }

    &-invitation-head {
      display: flex;
      margin-bottom: 24px;
      margin-top:12px;

      &-inviter {
        //width: 128px;
        height: 20px;
        font-size: 16px;
        font-family: alibaba-puhuiti;
        font-weight: 500;
        color: #595959;
        line-height: 20px;

        margin-top: 6px;
      }

      &-tenant {
        //width: 168px;
        height: 32px;
        font-size: 24px;
        font-family: alibaba-puhuiti;
        font-weight: 500;
        color: #262626;
        line-height: 32px;

        margin-top: 8px;
      }
    }
  }
}