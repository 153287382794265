.user-avatar-container {
  display: flex;
  align-items: center;
}
.user-name {
  display: inline-block;
  margin-left: 8px;
}
.user-dropmenu:hover {
  cursor: pointer;
}

.font {
  font-family: alibaba-puhuiti;
}

.pop-container{
  width: 240px;
  height: 196px;
  background: #FFFFFF;
  box-shadow: 0px 12px 48px 16px rgba(0,0,0,0.03), 0px 9px 28px 0px rgba(0,0,0,0.05), 0px 6px 16px -8px rgba(0,0,0,0.08);
  border-radius: 4px;

  //padding: 0;
}

.pop-container-head{
  width: 240px;
  height: 88px;

  background: url('../../../public/avatar-backgroud.png') no-repeat center center;

  display: flex;
  //flex-direction: column;

  padding-left: 24px;
  padding-top: 24px;

  //background-color: silver;
}

.pop-container-foot{
  width: 240px;
  height: 108px;

  //background-color: pink;

  padding-top: 8px;
  padding-left: 24px;
}
.pop-container-foot-account-setting{
  width: 192px;
  height: 40px;
  //background: #E8EAFF;
  border-radius: 4px;

  display: flex;
  align-items: center;
  cursor: pointer;

  padding-left: 12px;
}
.pop-container-foot-account-setting-label{
  //width: 64px;
  height: 16px;
  font-size: 16px;
  font-family: alibaba-puhuiti;
  font-weight: 500;
  color: rgba(38,38,38,0.85);
  line-height: 16px;

  cursor: pointer;
  margin-left: 5px;
}

.pop-container-foot-cursor-backgroup{
  background: #E8EAFF;
}

.pop-container-foot-logout{
  width: 192px;
  height: 40px;
  border-radius: 4px;
  //opacity: 0.08;


  display: flex;
  align-items: center;
  cursor: pointer;


  margin-top: 4px;
  padding-left: 12px;
}

.pop-container-foot-account-logout-label{
  width: 64px;
  height: 16px;
  font-size: 16px;
  font-family: alibaba-puhuiti;
  font-weight: 500;
  color: rgba(38,38,38,0.85);
  line-height: 16px;

  cursor: pointer;
  margin-left: 6px;
}



.pop-container-head-userName{
  width: 140px;
  height: 20px;
  font-size: 20px;
  font-family: alibaba-puhuiti;
  font-weight: 500;
  color: #262626;
  line-height: 20px;

  margin-top: 3px;
}

.pop-container-head-tenant{
  //width: 98px;
  height: 14px;
  font-size: 14px;
  font-family: alibaba-puhuiti;
  
  color: #595959;
  line-height: 14px;

  cursor: pointer;
  margin-top: 8px;
}
